import request from '@/utils/request'

//系统管理--角色管理--分页查询角色
export function getRoleList(data) {
  return request({
      url: '/admin_role/v1/list',
      method: 'post',
      data
  })
}

//系统管理--角色管理--添加角色
export function addRole(data) {
  return request({
      url: '/admin_role/v1/add',
      method: 'post',
      data
  })
}

//系统管理--角色管理--删除角色
export function deleteRole(data) {
  return request({
      url: '/admin_role/v1/delete',
      method: 'get',
      params:data
  })
}

//系统管理--角色管理--角色详情
export function getRoleDetail(data) {
  return request({
      url: '/admin_role/v1/detail',
      method: 'get',
      params:data
  })
}

//系统管理--角色管理--更新角色
export function editRole(data) {
  return request({
      url: '/admin_role/v1/edit',
      method: 'post',
      data
  })
}

//系统管理--角色管理--查询所有角色
export function getAllRole(data) {
  return request({
      url: '/admin_role/v1/listAll',
      method: 'post',
      data
  })
}

//系统管理--角色管理--查询所有角色
export function getUserDetail(data) {
  return request({
      url: '/admin_role/v1/detail',
      method: 'get',
      params:data
  })
}

//系统管理--角色管理--查询所有角色
export function getUserRoleAuth(data) {
  return request({
      url: '/admin_role/v1/roleAuth',
      method: 'get',
      params:data
  })
}