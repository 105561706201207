import request from '@/utils/request'

// 登录
export function login(data) {
  return request({
    url: '/auth/v1/login',
    method: 'post',
    data
  })
}

// 修改密码
export function updatepwd(data) {
  return request({
    url: '/user/updatePwd',
    method: 'put',
    data
  })
}
