<template>
  <el-container class="home-container">
    <el-container class="home-main">
      <ly-aside :is-collapse.sync="isCollapse" />
      <!-- 右侧主体 -->
      <el-container>
        <el-header class="root-header">
          <div class="header-bar">
            <div class="header-bar-left">
              <Breadcrumb :is-collapse.sync="isCollapse" />
            </div>
            <div class="header-bar-right">
              <el-dropdown class="header-status__dropDown">
                <div class="userInfo">
                  <i class="el-icon-bell" />
                  <span class="userInfo_border"></span>
                  <el-image class="new_img" :src="userAvatarKey" v-if="userAvatarKey" />
                  <el-avatar
                    v-else
                    shape="circle"
                    :size="18"
                    icon="el-icon-user-solid"
                    style="vertical-align: sub"
                  />
                  {{ userName }}
                  <i class="el-icon-arrow-down" />
                </div>
                <div class="floatRight">
                  
                </div>
                <el-dropdown-menu>
                  <!-- <el-dropdown-item @click.native="userClick">个人中心</el-dropdown-item>
                  <el-dropdown-item @click.native="dialogVisible = true" >修改密码</el-dropdown-item> -->
                  <el-dropdown-item @click.native="_logout">退出</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dialog
                ref="addVisible"
                title="修改密码"
                :visible.sync="dialogVisible"
                width="25%"
                @close="handleClose"
              >
                <el-form
                  ref="updatePwdObj"
                  :model="updatePwdObj"
                  :rules="updatePwdRules"
                  label-width="80px"
                >
                  <el-form-item label="旧密码" prop="oldPassword">
                    <el-input v-model="updatePwdObj.oldPassword" placeholder="请输入旧密码" />
                  </el-form-item>
                  <el-form-item label="新密码" prop="password">
                    <el-input v-model="updatePwdObj.password" placeholder="请输入新密码" />
                  </el-form-item>
                  <el-form-item label="确认密码" prop="surePassword">
                    <el-input v-model="updatePwdObj.surePassword" placeholder="请确认密码" />
                  </el-form-item>
                </el-form>
                <div>
                  <el-button @click="dialogVisible = false">取 消</el-button>
                  <el-button type="warning" @click="submitForm">确 定</el-button>
                </div>
              </el-dialog>
            </div>
          </div>
        </el-header>
        <el-main class="ly-main">
          <router-view class="main-view" />
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import LyAside from './components/Aside/index'
import Breadcrumb from './components/Breadcrumb/index'
import { updatepwd } from '@/api/login'

export default {
  name: 'Layout',

  components: {
    LyAside,
    Breadcrumb
  },

  data() {
    return {
      userAvatarKey: '', //头像
      isCollapse: false,
      dialogVisible: false,
      updatePwdObj: {
        oldPassword: null,
        password: null,
        userId: null,
        surePassword: null
      },
      userName: null, //当前登录账号
      updatePwdRules: {
        oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        surePassword: [{ required: true, message: '请确认密码', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.userName = sessionStorage.getItem('userName')
    this.userAvatarKey = sessionStorage.getItem('image')
  },
  methods: {
    //个人中心
    userClick() {
      const userId = sessionStorage.getItem('userId')
      this.$router.push({
        path: '/systemManagement/UserInforMation',
        query: { id: userId }
      })
    },
    _logout() {
      // console.log("123123");
      this.$message.success({
        message: '登出成功',
        duration: 800,
        onClose: async () => {
          sessionStorage.removeItem('token')
          sessionStorage.removeItem('userId')
          sessionStorage.removeItem('userName')
          sessionStorage.removeItem('image')
          sessionStorage.removeItem('mobile')
          sessionStorage.removeItem('menuTree')
          location.reload()
        }
      })
    },

    async updatePassword() {
      this.dialogVisible = true
    },

    handleClose() {
      this.$refs.updatePwdObj.resetFields()

      this.dialogVisible = false
    },

    submitForm() {
      this.$refs.updatePwdObj.validate(async (valid) => {
        if (valid) {
          const userId = sessionStorage.getItem('userId')

          Object.assign(this.updatePwdObj, { userId })

          const { data: res } = await updatepwd(this.updatePwdObj)

          if (res.success) {
            this.$message.success({
              message: '密码修改成功',
              duration: 800,
              onClose: async () => {
                sessionStorage.removeItem('token')
                location.reload()
              }
            })

            this.dialogVisible = false
          } else {
            this.$messageError(res.message)
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-dropdown-menu .el-dropdown-menu__item:focus,
.el-dropdown-menu .el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #f5f7fa !important;
}
/deep/ .new_img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 6px;
}
/deep/ .el-main {
  padding: 0px !important;
}
.home-container {
  /deep/ .el-header {
    height: auto !important;
  }
  height: 100%;
  .root-header {
    height: 64px !important;
    line-height: 64px;
    padding: 0 0 0 20px;
    .header-bar {
      height: 64px;
      display: flex;
      justify-content: space-between;
      .header-bar-right {
        color: #000000 !important;
        width: 500px;
        display: flex;
        justify-content: flex-end;  
        background-size: 500px 64px;
        float: right;
        .header-status__dropDown {
          margin-right: 30px;
          .userInfo {
            display: flex;
            flex-direction: row;
            align-items: center;
            .el-icon-bell{
              font-size: 20px;
            }
            .userInfo_border{
              width: 1px;
              height: 16px;
              border-right: 1px solid #151515;
            }
            &_border {
 
              margin: 0 15px;
            }
            .el-avatar {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .ly-main {
    height: 0;
    margin-top: 5px;
    overflow-x: hidden; // TODO 此处可能会影响到整个main的滚动
    background: #f6f6f6;
    .main-view {
      // height: 100%;  
    }
  }
  .home-main {
    .main_header {
      margin-top: 16px;
    }
  }

  /deep/ .el-dropdown {
    display: flex;
  }

  .floatRight {
    float: right;
    cursor: pointer;
    margin-left: 5px;
  }
}
  
</style>
