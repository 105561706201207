export default {
  namespaced: true,
  state() {
    return {
      chidrenQueryInfo: {}
    }
  },
  mutations: {
    changeChidrenQueryInfo(state, info) {
      state.chidrenQueryInfo = info
    },
    removeChidrenQueryInfo(state) {
      state.chidrenQueryInfo = {}
    }
  }
}
