import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../static/fonts/iconfont.css'
import '../static/css/bts.css'
import '../static/css/index.css'
import '../static/css/global.less'
// /* 时间格式化 doc - https://www.npmjs.com/package/dateformat */
import df from 'dateformat'

Vue.use(ElementUI)

import { message, MessageError, MessageSuccess, MessageWaring } from './utils/tool'

Vue.config.productionTip = false
Vue.prototype.$message = message
Vue.prototype.$df = df

Vue.prototype.$messageError = MessageError

Vue.prototype.$messageSuccess = MessageSuccess

Vue.prototype.$messageWarning = MessageWaring
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
