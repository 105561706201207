import RouterView from '@/components/RouterView'

const menuList = [
  {
    path: '/user',
    component: RouterView,
    redirect: '/user/userManagement',
    name: 'user',
    meta: {
      title: '用户管理',
      isMenu: true
    },
    children: [
      {
        path: '/user/userManagement',
        meta: {
          title: '用户管理',
          isMenu: false
        },
        name: 'userManagement',
        component: () => import('../views/user/userManagement/index.vue'),
      },
      {
        path: '/userManagement/userDetail',
        name: 'userDetail',
        component: () => import('../views/user/userManagement/components/userDetail.vue'),
        meta: {
          title: '用户详情',
        },
      },
      {
        path: '/userManagement/userDetail/userDetailList',
        name: 'userDetailList',
        component: () => import('../views/user/userManagement/components/userDetailList.vue'),
        meta: {
          title: '查看详情',
        },
      },
      {
        path: '/famousMuser/Newfamous',
        name: 'userDetailList',
        component: () => import('../views/user/famousMuser/Newfamous.vue'),
        meta: {
          title: '新建名帖',
        },
      },
      {
        path: '/user/VIPManagement',
        meta: {
          title: '会员管理',
        },
        name: 'VIPManagement',
        component: () => import('../views/user/VIPManagement/index.vue')
      },
      {
        path: '/VIPManagement/userContact',
        meta: {
          title: '想联系',
        },
        name: 'userContact',
        component: () => import('../views/user/VIPManagement/components/userContact.vue')
      },
    ]
  },
  {
    path: '/activity',
    component: RouterView,
    redirect: '/activity/list',
    meta: {
      title: '活动管理',
      isMenu: true
    },
    children: [
      {
        path: '/activity/list',
        meta: {
          title: '活动管理',
          isMenu: false
        },
        name: 'Activity',
        component: () => import('../views/activity/index.vue'),
      },
      {
        path: '/activity/newlist',
        name: 'newlist',
        component: () =>
          import('../views/activity/newList.vue'),
        meta: {
          title: '新增活动',
          isMenu: false
        }
      },
      {
        path: '/activity/manageList',
        name: 'manageList',
        component: () =>
          import('../views/activity/manageList.vue'),
        meta: {
          title: '报名管理',
          isMenu: false
        }
      },
      {
        path: '/activity/signInManage',
        name: 'signInManage',
        component: () =>
          import('../views/activity/signInManage.vue'),
        meta: {
          title: '签到管理',
          isMenu: false
        }
      },
      {
        path: '/activity/distributionManage',
        name: 'distributionManage',
        component: () =>
          import('../views/activity/distributionManage.vue'),
        meta: {
          title: '分销管理',
          isMenu: false
        }
      },
      {
        path: '/activity/distributionList',
        name: 'distributionList',
        component: () =>
          import('../views/activity/distributionList.vue'),
        meta: {
          title: '分销列表',
          isMenu: false
        }
      }



    ]
  },
  {
    path: '/information',
    component: RouterView,
    redirect: '/information/list',
    name: 'information',
    meta: {
      title: '商讯管理',
      isMenu: true
    },
    children: [
      {
        path: '/information/list',
        meta: {
          title: '商讯列表',
          isMenu: false
        },
        name: 'information',
        component: () => import('../views/information/list/index.vue'),
      },
      {
        path: '/information/informationList',
        name: 'informationList',
        component: () =>
          import('../views/information/addinfo/informationList.vue'),
        meta: {
          title: '新增商讯',
          isMenu: false
        }
      },
    ]
  },

  {
    path: '/visiting',
    component: RouterView,
    redirect: '/visiting/list',
    name: 'visiting',
    meta: {
      title: '名帖管理',
      isMenu: true
    },
    children: [
      {
        path: '/visiting/list',
        meta: {
          title: '名帖列表',
          isMenu: false
        },
        name: 'visiting',
        component: () => import('../views/visiting/list/index.vue'),
      },
      {
        path: '/visiting/visitingList',
        name: 'visitingList',
        component: () =>
          import('../views/visiting/addinfo/visitingList.vue'),
        meta: {
          title: '新增名帖',
          isMenu: false
        }
      },
    ]
  },
 

  {
    path: '/orderManagement',
    component: RouterView,
    redirect: '/businessManagement/orderManagement',
    meta: {
      title: '订单管理',
      isMenu: true
    },
    children: [
      {
        path: '/businessManagement/orderManagement',
        meta: {
          title: '订单管理',
          isMenu: false
        },
        name: 'orderManagement',
        component: () => import('../views/businessManagement/orderManagement/index.vue'),
      }
    ]
  },
  {
    path: '/businessHub',
    component: RouterView,
    redirect: '/businessHub/businessTopics',
    name: 'businessHub',
    meta: {
      title: '商业枢纽',
      isMenu: true
    },
    children: [
      {
        path: '/businessHub/businessTopics',
        meta: {
          title: '商业话题',
          isMenu: false
        },
        name: 'businessTopics',
        component: () => import('../views/businessHub/businessTopics/index.vue')
      },
      {
        path: '/businessTopics/info',
        meta: {
          title: '想联系',
        },
        name: 'info',
        component: () => import('../views/businessHub/businessTopics/components/info.vue')
      },
      {
        path: '/businessTopics/addTopics',
        meta: {
          title: '新增话题',
          isMenu: false
        },
        name: 'addTopics',
        component: () => import('../views/businessHub/businessTopics/components/addTopics.vue')
      },
      {
        path: '/businessHub/supplyInfo',
        meta: {
          title: '供给信息',
          isMenu: false
        },
        name: 'supplyInfo',
        component: () => import('../views/businessHub/supplyInfo/index.vue')
      },
      {
        path: '/supplyInfo/infoDetail',
        meta: {
          title: '提交需求',
          isMenu: false
        },
        name: 'infoDetail',
        component: () => import('../views/businessHub/supplyInfo/components/infoDetail.vue')
      },
      {
        path: '/supplyInfo/supplyInfo',
        meta: {
          title: '报备供给',
          isMenu: false
        },
        name: 'supplyInfo',
        component: () => import('../views/businessHub/supplyInfo/components/supplyInfo.vue')
      },
      {
        path: '/businessHub/infoPush',
        meta: {
          title: '消息推送',
          isMenu: false
        },
        name: 'infoPush',
        component: () => import('../views/businessHub/infoPush/index.vue')
      },
      {
        path: '/infoPush/addInfo',
        meta: {
          title: '新增信息',
          isMenu: false
        },
        name: 'addInfo',
        component: () => import('../views/businessHub/infoPush/components/addInfo.vue')
      },
      {
        path: '/infoPush/userInfoPush',
        meta: {
          title: '新增信息',
          isMenu: false
        },
        name: 'userInfoPush',
        component: () => import('../views/businessHub/infoPush/components/userInfoPush.vue')
      },
      {
        path: '/infoPush/readingMessage',
        meta: {
          title: '已读信息',
          isMenu: false
        },
        name: 'readingMessage',
        component: () => import('../views/businessHub/infoPush/components/readingMessage.vue')
      },
      {
        path: '/infoPush/distributionManage',
        meta: {
          title: '分销管理',
          isMenu: false
        },
        name: 'distributionManage',
        component: () => import('../views/businessHub/supplyInfo/components/distributionManage.vue')
      },
      {
        path: '/infoPush/distributionList',
        meta: {
          title: '分销列表',
          isMenu: false
        },
        name: 'distributionList',
        component: () => import('../views/businessHub/supplyInfo/components/distributionList.vue')
      }
    ]
  },
  {
    path: '/DistributionController',
    component: RouterView,
    redirect: '/distribution/list',
    name: '/DistributionController',
    meta: {
      title: '分销管理',
      isMenu: false
    },
    children: [
      {
        path: '/distribution/list',
        meta: {
          title: '分销管理',
          isMenu: false
        },
        component: () => import('../views/distribution/index.vue')
      },
      {
        path: '/distribution/activityDistribution',
        meta: {
          title: '活动分销',
          isMenu: false
        },
        component: () => import('../views/distribution/components/activityDistribution.vue')
      },
      {
        path: '/distribution/demandDistribution',
        meta: {
          title: '需求分销',
          isMenu: false
        },
        component: () => import('../views/distribution/components/demandDistribution.vue')
      },
      {
        path: '/distribution/filingDistribution',
        meta: {
          title: '报备分销',
          isMenu: false
        },
        component: () => import('../views/distribution/components/filingDistribution.vue')
      },
    ]
  },
  {
    path: '/businessManagement',
    component: RouterView,
    redirect: '/businessManagement/objectManagement',
    name: 'businessManagement',
    meta: {
      title: '业务管理',
      isMenu: true
    },
    children: [
      {
        path: '/businessManagement/objectManagement',
        meta: {
          title: '项目管理',
          isMenu: false
        },
        name: 'objectManagement',
        component: () => import('../views/businessManagement/objectManagement/index.vue')
      },
      {
        path: '/objectManagement/addObject',
        meta: {
          title: '新增项目',
          isMenu: false
        },
        name: 'addObject',
        component: () => import('../views/businessManagement/objectManagement/components/addObject.vue')
      },
      {
        path: '/objectManagement/objectDetail',
        meta: {
          title: '查看详情',
          isMenu: false
        },
        name: 'objectDetail',
        component: () => import('../views/businessManagement/objectManagement/components/objectDetail.vue')
      },
    ]
  },
  {
    path: '/systemManagement',
    component: RouterView,
    redirect: '/systemManagement/objectManagement',
    name: 'systemManagement',
    meta: {
      title: '系统管理',
      isMenu: true
    },
    children: [
      {
        path: '/systemManagement/adminManagement',
        meta: {
          title: '管理员管理',
          isMenu: false
        },
        name: 'adminManagement',
        component: () => import('../views/systemManagement/adminManagement/index.vue')
      },
      {
        path: '/systemManagement/bannerManagement',
        meta: {
          title: 'Banner管理',
          isMenu: false
        },
        name: 'bannerManagement',
        component: () => import('../views/systemManagement/bannerManagement/index.vue')
      },
      {
        path: '/systemManagement/menuManagement',
        meta: {
          title: '菜单管理',
          isMenu: false
        },
        name: 'menuManagement',
        component: () => import('../views/systemManagement/menuManagement/index.vue')
      },
      {
        path: '/systemManagement/roleManagement',
        meta: {
          title: '角色管理',
          isMenu: false
        },
        name: 'roleManagement',
        component: () => import('../views/systemManagement/roleManagement/index.vue')
      },
    ]
  },
]

export default menuList