<template>
  <div class="menuList hp-100">
    <el-aside :width="isCollapse ? '64px' : '210px'">
      <el-scrollbar
        wrap-class="scrollbar-wrapper"
        :native="false"
        wrap-style="color: red; height:770px;overflow-x: hidden;"
      >
        <div class="header-bar-left">
          <div class="logo">
            <el-image :src="logo" />
          </div>
          <!-- <div class="logo2">
            <el-image :src="logo2" />
          </div> -->
        </div>
        <el-menu
          :collapse="isCollapse"
          :collapse-transition="false"
          router
          unique-opened
          :default-active="activePath"
          style="padding-top: 20px"
          @select="handleSelect"
        >
          <template v-for="item in menuList">
            <!-- 一级菜单 -->
            <!-- item.children.length === 0 接口发送-->
            <!-- !item.hasOwnProperty('children') 自己编辑-->
            <!-- <router-link :to="item.url" :key="item.id" v-if="item.children && item.children.length === 0"> -->
            <router-link :to="item.url" :key="item.id" v-if="item.children === null">
              <el-menu-item :index="item.url + ''">
                <span class="span_title"></span>
                <!-- <i :class="item.icon" class="iconfont" /> -->
                <img
                  :src="item.icon"
                  alt=""
                  style="width: 26px; height: 26px; z-index: 2; padding-right: 12px"
                />
                <span>{{ item.authName }}</span>
                <!-- <i
                  v-if="isShow"
                  class="iconfont icon-jr-icon-back"
                  style="width: 4px; padding-left: 50px"
                /> -->
              </el-menu-item>
            </router-link>
            <el-submenu v-else :key="item.id" :index="item.url + ''">
              <!-- 一级菜单模板区域 -->
              <template slot="title">
                <span class="span_title"></span>
                <!-- 图标 -->
                <!-- <i :class="item.icon" class="iconfont" style="z-index: 2" /> -->
                <img
                  :src="item.icon"
                  alt=""
                  style="width: 26px; height: 26px; z-index: 2; padding-right: 12px"
                />
                <!-- 文本 -->
                <span style="z-index: 2">{{ item.authName }}</span>
              </template>
              <!-- 二级菜单 -->
              <el-menu-item
                v-for="subItem in item.children"
                :key="subItem.id"
                :index="subItem.url"
                style="padding-left: 55px"
              >
                <template slot="title">
                  <span class="span_title"></span>
                  <!-- 图标 -->
                  <!--  <i :class="subItem.meta.icon" />-->
                  <!-- 文本 -->
                  <span style="z-index: 2">{{ subItem.authName }}</span>
                  <i
                    v-if="menu1 == subItem.url"
                    class="iconfont icon-jr-icon-back"
                    style="width: 4px; padding-left: 50px"
                  />
                </template>
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </el-scrollbar>
    </el-aside>
  </div>
</template>
<script>
import logo from '../../../../static/images/logo3.png'
import logo2 from '../../../../static/images/logo2.png'
import { getUserDetail } from '@/api/role.js'
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      logo,
      logo2,
      menuList: [], // 菜单列表
      activePath: '', // 当前路由
      menu1: '',
      isShow: false
    }
  },
  created() {
    this.search()
  },
  methods: {
    async search() {
      let id = sessionStorage.getItem('roleId')
      const { data: res } = await getUserDetail({ id: id })
      this.menuList = res.data.auths
      // console.log(res);
      console.log(this.menuList)
    },
    saveNavState() {
      this.$store.dispatch('removeOtherQueryInfo')
    },
    handleSelect(a, a2) {
      if (a === '/DistributionController') {
        this.isShow = true
      } else {
        this.isShow = false
      }
      // console.log(a)
      this.menu1 = a
    }
  }
}
</script>
<style scoped lang="less">
.el-menu-item.is-active {
  background: #7c71e1;
  color: #ffffff;
}

.menuList {
  padding: 0px 15px;
  background-color: #ffffff;

  .el-menu {
    border: none;
  }

  a {
    text-decoration: none;
  }

  .router-link-active {
    text-decoration: none;
  }

  .one /deep/.is-active {
    background: #d1d3ff !important;
  }

  .iconfont {
    margin-right: 4px;
  }

  .header-bar-left {
    font-size: 18px;
    font-family: 'PingFang SC';
    font-weight: 500;
    display: flex;

    .logo {
      width: 185px;
      height: 32px;
      margin-left: 10px;
      margin-top: 10px;
    }

    // .logo2 {
    //   width: 130px;
    //   height: 50px;
    //   margin-top: 18px;
    // }

    .el-image {
      width: 100%;
      height: 100%;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}
</style>
