export default {
  namespaced: true,
  state() {
    return {
      matchQueryInfo: {}, // 人岗匹配
      allSearchQueryInfo: {}, // 全网搜索
      communicateQueryInfo: {} // 沟通列表
    }
  },
  mutations: {
    changeMatchQueryInfo(state, info) {
      state.matchQueryInfo = info
    },
    changeAllSearchQueryInfo(state, info) {
      state.allSearchQueryInfo = info
    },
    changeCommunicateQueryInfo(state, info) {
      state.communicateQueryInfo = info
    },
    removeQueryInfo(state) {
      state.matchQueryInfo = {}
      state.allSearchQueryInfo = {}
      state.communicateQueryInfo = {}
    }
  }
}
