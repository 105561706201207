import Vue from 'vue'
import Vuex from 'vuex'
import chidrenRouter from './chidrenRouter'
import projectCenter from './projectCenter/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state() {
    return {
      queryInfo: {}
    }
  },

  mutations: {
    changeQueryInfo(state, info) {
      state.queryInfo = info
    },
    removeQueryInfo(state) {
      state.queryInfo = {}
    }
  },

  actions: {
    removeOtherQueryInfo({ commit }) {
      commit('removeQueryInfo')
      commit('projectCenter/removeQueryInfo')
      commit('chidrenRouter/removeChidrenQueryInfo')
    }
  },

  modules: {
    chidrenRouter,
    projectCenter
  }
})
