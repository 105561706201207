import { Message } from 'element-ui'
let messageInstance = null
const resetMessage = (options) => {
  if (messageInstance) {
    messageInstance.close()
  }
  messageInstance = Message(options)
}
;['error', 'success', 'info', 'warning'].forEach((type) => {
  resetMessage[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    return resetMessage(options)
  }
})
export const message = resetMessage

export function MessageError(params) {
  if (!params || typeof params === 'string') {
    params = {
      message: params
    }
  }

  if (!params.message) {
    params.message = '操作失败'
  }

  Message(
    Object.assign(params, {
      type: 'error',
      duration: 1800,
      customClass: 'max-z'
    })
  )
}

export function MessageSuccess(params) {
  if (!params || typeof params === 'string') {
    params = {
      message: params
    }
  }

  if (!params.message) {
    params.message = '操作成功'
  }

  Message(
    Object.assign(params, {
      type: 'success',
      // message: '操作成功',
      duration: 1600,
      customClass: 'max-z'
    })
  )
}

export function MessageWaring(params) {
  if (!params || typeof params === 'string') {
    params = {
      message: params
    }
  }

  if (!params.message) {
    params.message = '请输入提示内容'
  }

  Message(
    Object.assign(params, {
      type: 'warning',
      duration: 1600,
      customClass: 'max-z'
    })
  )
}

export function formatFileData(params) {
  const arr = []
  let arr1 = []
  params.forEach((item) => {
    arr.push(item.file.split('/'))
    arr1 = arr.map((el) => {
      return el[el.length - 1].substring(36)
    })
  })

  return arr1
}

export function formatImg(params) {
  const arr2 = params.split('/')

  return arr2[arr2.length - 1].substring(36)
}
