<template>
  <div>
    <el-breadcrumb>
      <span class="title">当前位置: </span>
      <el-breadcrumb>
        <template v-for="(item, i) in levelList">
          <el-breadcrumb-item
            v-if="item.meta.title"
            :key="item.path"
            class="breadcrumb-item"
            :to="getPath(item.path)"
            >{{
              $route.params.paramsName && i === 2
                ? '' + $route.params.paramsName + ' ' + item.meta.title
                : item.meta.title
            }}</el-breadcrumb-item
          >
        </template>
      </el-breadcrumb>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      levelList: [],
      params: {}
    }
  },
  computed: {
    getPath() {
      return function (path) {
        let pathlocal = path

        if (pathlocal.indexOf(':') !== -1 && this.$route.params) {
          const paramsIndex = pathlocal.indexOf(':')

          const paramsStr = pathlocal.substring(paramsIndex).substring(1)

          pathlocal = pathlocal.substring(0, paramsIndex)

          pathlocal = pathlocal + this.$route.params[paramsStr]

          if (path !== pathlocal) {
            return pathlocal
          } else {
            return path
          }
        } else {
          return path
        }
      }
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    // 切换侧边栏展开状态
    toggleCollapse() {
      this.$emit('update:isCollapse', !this.isCollapse)
    },
    // 动态面包屑
    getBreadcrumb() {
      const matched = this.$route.matched.filter((item) => item.name)

      // const first = matched[0]
      // if (first && first.name.trim().toLocaleLowerCase() !== 'Dashboard'.toLocaleLowerCase()) {
      //   matched = [{ path: '/dashboard', meta: { title: 'dashboard' } }].concat(matched)
      // }

      this.levelList = matched
    }
  }
}
</script>

<style lang="less" scoped>
.title{
  margin: 7px 5px 0 0;
  color: #333333;
}
/deep/ .el-breadcrumb {
  height: 40px;
  display: flex;
  align-items: center;
  color: #333333 !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-top: 7px;

  .el-breadcrumb__inner {
    color: #333333 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
  }

  .top_icon {
    padding: 0 20px;
    color: #8d8d8d;
    font-size: 16px;
    cursor: pointer;
  }
  .top_icon:hover {
    color: #000000;
  }
}
.breadcrumb-item:nth-child(1) {
  display: none;
}
</style>
